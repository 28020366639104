import { Route, Routes } from "react-router-dom";
import CV from "./pages/cv/cv";
import Teaching from "./pages/teaching/teaching";
import NavigationBar from "./components/NavigationBar";
import Publications from "./pages/publications/publications";
import Snake from "./pages/snake/snake";
import Home from "./pages/home/home";
import "./App.css";
import "./theme.css";

function App() {
  return (
    <div className="App">
      <NavigationBar />
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/teaching" element={<Teaching />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/snake" element={<Snake />} />
      </Routes>
    </div>
  );
}

export default App;
