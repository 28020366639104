import DownloadButton from "../DownloadButton/DownloadButton";
import "./TeachingCard.css";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const TeachingCard = ({
  title,
  desc,
  pathFolder,
  hrefDownload = false,
  hrefInfo = false,
  hrefR = false,
  hrefDoneAll = false,
  hrefPython = false,
  blurred = false,
}) => {
  return (
    <Card
      className={blurred ? "teaching__card card-disabled" : "teaching__card"}
    >
      <CardContent>
        <div className="teaching__card__title">{title}</div>
        <div className="teaching__card__desc">{desc}</div>
      </CardContent>
      <CardActions>
        <DownloadButton
          icon={"sujet"}
          pathFolder={pathFolder}
          pathFile={hrefDownload}
          disabled={blurred}
        />
        <DownloadButton
          icon={"aide"}
          pathFolder={pathFolder}
          pathFile={hrefInfo}
          disabled={blurred}
        />
        <DownloadButton
          icon={"code"}
          pathFolder={pathFolder}
          pathFile={hrefR}
          disabled={blurred}
        />
        <DownloadButton
          icon={"doneall"}
          pathFolder={pathFolder}
          pathFile={hrefDoneAll}
          disabled={blurred}
        />
        <DownloadButton
          icon={"python"}
          pathFolder={pathFolder}
          pathFile={hrefPython}
          disabled={blurred}
        />
      </CardActions>
    </Card>
  );
};

export default TeachingCard;
