import "./cv.css";
import Grid from "@mui/material/Grid";

import * as React from "react";

import WorkTimeline from "../../components/Timelines/WorkTimeline";
import EducationTimeline from "../../components/Timelines/EducationTimeline";

const lineHeight = "48px";

const CV = () => {
  return (
    <div>
      <Grid
        container
        columns={{ xs: 6, sm: 6, md: 6, lg: 12 }}
        maxWidth={{ md: "900px", lg: "1300px" }}
        margin={"auto"}
        style={{
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <Grid item xs={6} padding={"10px"}>
          <WorkTimeline lineHeight={lineHeight} />
        </Grid>
        <Grid item xs={6} padding={"10px"} margin={"auto auto 150px auto"}>
          <EducationTimeline lineHeight={lineHeight} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CV;
