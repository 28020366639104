import { useState } from "react";
import { Button } from "@mui/material";
import Popover from "@mui/material/Popover";

import DownloadIcon from "@mui/icons-material/Download";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import { FaInfoCircle, FaPython, FaRProject } from "react-icons/fa";

import "./DownloadButton.css";

const dictIcons = {
  sujet: <DownloadIcon />,
  code: <FaRProject size={20} />,
  doneall: <DoneAllIcon />,
  aide: <FaInfoCircle />,
  python: <FaPython size={20} />,
};

const dictPopover = {
  sujet: "Sujet du TP",
  code: "Correction R",
  doneall: "Correction",
  aide: "Document supplémentaire",
  python: "Correction Python",
};

const DownloadButton = ({ icon, pathFolder, pathFile, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disabled={!pathFile || disabled}
      >
        <a
          className="download__link"
          href={"files/" + pathFolder + pathFile}
          download={pathFile}
          target="_blank"
          rel="noreferrer"
        >
          {dictIcons[icon]}
        </a>
      </Button>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="popover__text">{dictPopover[icon]}</div>
      </Popover>
    </div>
  );
};

export default DownloadButton;
