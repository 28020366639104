import "./PublicationCard.css";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";

import { SiGooglescholar } from "react-icons/si";

import Grid from "@mui/material/Grid";

import ArticleIcon from "@mui/icons-material/Article";

const PublicationCard = ({
  title,
  authors,
  journal,
  pathPaper,
  pathScholar,
  pathImage,
}) => {
  return (
    <Card className="publication__card">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4} className="publication__image__grid">
            <img
              alt="Conference logo"
              className="publication__image__conference"
              src={pathImage}
            />
          </Grid>
          <Grid item xs={8}>
            <div className="publication__card__title">{title}</div>
            <div className="publication__card__authors">{authors}</div>
            <div className="publication__card__journal">{journal}</div>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className="publications__card__actions">
        <Button disabled={!pathPaper}>
          <a href={pathPaper} target="_blank" rel="noreferrer">
            <ArticleIcon />
          </a>
        </Button>
        <Button disabled={!pathScholar}>
          <a href={pathScholar} target="_blank" rel="noreferrer">
            <SiGooglescholar />
          </a>
        </Button>
      </CardActions>
    </Card>
  );
};

export default PublicationCard;
