import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";

import SchoolIcon from "@mui/icons-material/School";

const EducationTimeline = ({ lineHeight = "48px" }) => {
  return (
    <div>
      <h1 className="cv__header">
        <SchoolIcon sx={{ fontSize: "48px", marginBottom: "10px" }} /> Education
      </h1>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.5,
          },
        }}
      >
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: "auto 0" }}>
            <div className="cv__date">(2020 - 2021)</div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ width: "0px" }} />
            <TimelineDot color="primary" variant="outlined"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: lineHeight, px: 2 }}>
            <div className="cv__item">Master IASD</div>
            <div className="cv__desc">PSL (Dauphine, ENS Ulm, Mines Paris)</div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: "auto 0" }}>
            <div className="cv__date">(2017 - 2020)</div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary" variant="outlined"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: lineHeight, px: 2 }}>
            <div className="cv__item">Engineering Degree</div>
            <div className="cv__desc">Telecom Paris</div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent sx={{ m: "auto 0" }}>
            <div className="cv__date">(2015 - 2017)</div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary" variant="outlined"></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: lineHeight, px: 2 }}>
            <div className="cv__item">PCSI / PC*</div>
            <div className="cv__desc">Chaptal Paris</div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
};

export default EducationTimeline;
